

.bodyStarter, .contactStarter, .servicesStarter, .aboutUsStarter  {
    position: relative;
  }
  
  .bodyStarter h1, .contactStarter h1, .servicesStarter h1, .aboutUsStarter h1
  {
    font-family: Roboto;
  }
  .bodyStarter::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/general.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(50%);
    z-index: -1; /* Place the overlay behind the content */
  }
  
  .homeBody{
    background-image: url("../../public/images/cleaning.avif");
    background-position: center;
    z-index: 40;
  }

  .bodyFooter {
    position: relative;
  }
  
  .bodyFooter::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public//images/fumigation2.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(50%);
    z-index: -1; /* Place the overlay behind the content */
    /* border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; */
  }

  .contactStarter::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/screenshot3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(50%);
    z-index: -1; /* Place the overlay behind the content */
  }
  

  .servicesStarter::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/screenshot2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(50%);
    z-index: -1; /* Place the overlay behind the content */
  }

  .aboutUsStarter::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/screenshot4.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(50%);
    z-index: -1; /* Place the overlay behind the content */
  }
  
  h1{
    font-family: Nunito;
  }

  @font-face {
    font-family: Nunito;
    src: url("../../public/fonts/Nunito/static/Nunito-Bold.ttf");
  }

  @font-face {
    font-family: Roboto;
    src: url("../../public/fonts/Roboto/Roboto-Black.ttf");
  }

  .lastText{
    font-family: Roboto;
  }